<template>
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <b-button  v-if="activeProductions!=0" v-b-toggle.cu-production variant="primary">
                <feather-icon icon="PlusIcon" />
                NEW PRODUCTION
            </b-button>
            <b-sidebar
                ref="myQuestion"
                id="cu-production"
                right
                backdrop
                shadow
                width="450px"
                sidebar-class="sidebar-lg"
                bg-variant="white"
            >
                <template #header="{ hide }">
                  <div class="d-flex justify-content-between align-items-center content-sidebar-header w-100">
                    <h5 class="mb-0">Add Production</h5>
                    <div>
                      <feather-icon
                          class="ml-1 cursor-pointer"
                          icon="XIcon"
                          size="16"
                          @click="hide"
                      />
                    </div>
                  </div>
                </template>
                <modal-edit-production :prod-to-edit="{}"></modal-edit-production>
            </b-sidebar>
            <!--                    <feather-icon icon="EditIcon" size="20" class="align-text-center"/>-->
        </div>
        <hr class="mb-2 mt-2">
        <h3 class="mb-1" v-if="activeProductions!=0">Active productions</h3>
        <div  v-else id="alertNoProd" class="qf-intro-no-prod justify-content-center align-items-center text-center">
            <h3 class="pt-4 d-flex justify-content-center align-items-center text-center">Welcome to Cineself!</h3><br>
            <h4 >You have no active productions yet. <br> To get started, click on "New Production" to setup your first one.</h4><br>
            <b-button v-b-toggle.cu-production variant="primary">
                <feather-icon icon="PlusIcon" />
                NEW PRODUCTION
            </b-button>
        </div>

        <b-row>
            <!--      Loop starts here-->
            <div class="qf-production-item" v-for="activeProd in activeProductions" :key="activeProd.id">
                <b-card no-body class="overflow-hidden object-fit-cover qf-active-prod h-100 mb-0 cursor-pointer" @click="navToProd(activeProd)">
                    <b-card-img :src="prodCoverUrl(activeProd)" alt="Image" rounded class="mb-1 badge-minimal qf-prod-cover"/>
                    <b-card-body :title="truncateString(activeProd.attributes.name, 45,' ...')" class="p-0 qf-prod-body">
                        <b-card-text v-if="activeProd.attributes.synopsis">
                            {{truncateString(activeProd.attributes.synopsis, 130, " ...")}}
                        </b-card-text>
                    </b-card-body>
                    <b-badge v-if="false" class="qf-badge">4</b-badge>
                </b-card>
            </div>
        </b-row>
        <h3 v-if="archivedProductions!=0" class="mb-1">Archived Productions</h3>
        <b-row>
            <!--      Loop starts here-->
            <div v-for="archProd in archivedProductions" :key="archProd.id" md="2" class="qf-production-item qf-dimm">
                <b-card no-body class="overflow-hidden object-fit-cover qf-active-prod h-100 mb-0 cursor-pointer" >
                    <b-row no-gutters>
                        <b-card-img :src="prodCoverUrl(archProd)" alt="Image" class="mb-1 badge-minimal qf-prod-cover"/>
                        <b-card-body :title="archProd.attributes.name" class="p-0">
                            <b-card-text>
                                {{archProd.attributes.synopsis}}
                            </b-card-text>
                        </b-card-body>
                    </b-row>
                </b-card>
            </div>
        </b-row>

    </div>
</template>


<script>
import {
    BCard,
    BCardText,
    BCardImg,
    BRow,
    BCardBody,
    BButton,
    BBadge,
    BSidebar,
    VBToggle,
} from 'bootstrap-vue';
import Parse from 'parse';
import store from "@/store";
import ModalEditProduction from "@/modals/ModalEditProduction";

export default {
    components: {
        ModalEditProduction,
        BCard,
        BCardText,
        BCardImg,
        BRow,
        BCardBody,
        BButton,
        BBadge,
        BSidebar,
    },
    directives: {
        'b-toggle': VBToggle,
    },
    data(){
        return {
            archivedProductions: [],
            activeProductions: [],
            curUser: {},
            selectedProduction: {}
        }
    },
    mounted() {
        this.getProductions();
    },
    computed: {
        allProds(){
            return store.getters['app/allProductions'];
        }
    },
    watch: {
        allProds: {
            immediate: true,
            handler(newSet){
                if(newSet){
                    this.sortProductions(newSet);
                }
            }
        }
    },
    methods: {
        navToProd(prodObj){
            store.commit('app/SET_PRODUCTION', prodObj);
            this.$router.push({path: 'production/'+prodObj.id+'/overview'});
        },
        prodCoverUrl(production) {
            let productionImg = this.defaultLogo('svg');

            if (production.attributes.coverImage) {
                productionImg = production.attributes.coverImage._url;
            }

            return productionImg;
        },
        sortProductions(productions){
            let self = this;
            self.archivedProductions = [];
            self.activeProductions = [];

            if(productions && productions.length>0){
              for(let i=0; i<productions.length; i++){
                if(productions[i].attributes.isArchived){
                  self.archivedProductions.push(productions[i]);
                }else{
                  self.activeProductions.push(productions[i]);
                }
              }
            }
                // productions.forEach(function(oneProd){
                //     if(oneProd.attributes.isArchived){
                //         self.archivedProductions.push(oneProd);
                //     }else{
                //         self.activeProductions.push(oneProd);
                //     }
                // });
            // }
        },
        getProductions(){
          if(!this.curUserId){
              this.getCurrentUser();
          }
          let self = this;
          let ProdObj = Parse.Object.extend('Production');
          let prodQuery = new Parse.Query(ProdObj);

          prodQuery.containedIn('teamMembers', [this.curUserId]);
          prodQuery.include('billingCompany');
          prodQuery.descending('createdAt');
          prodQuery.find()
              .then(function(results){
                self.$store.commit('app/SET_ALL_PRODUCTIONS', results);
              })
              .catch(function(err){
                console.log("error", err.message);
              });
        },
        getCurrentUser(){
            let currentUser = Parse.User.current();
            if(currentUser){
                this.curUser = currentUser;
                this.curUserId = currentUser.id;
            }
        }
    }

}
</script>

<style>
.card img.qf-prod-cover {
    height: 300px;
    background: #000013;
}
.qf-dimm {
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.5s ease-out;
}
.qf-dimm:hover {
    opacity: 1;
}
.qf-active-prod {
    transition: all 0.5s ease-out;
    padding: 10px 10px 14px 10px;
    position: relative;
}
.qf-active-prod:hover {
    background: #3d4865;
}
.qf-production-item {
    padding: 0 15px;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 275px;
    min-width: 267px;
    margin-bottom: 30px;
}
.qf-production-item .card-title{
    margin-bottom: 10px;
}

#alertNoProd{
    height: 100%;
    min-height:100%;
}

#alertNoProd:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('/assets/images/no-prod-bg.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    /* background-position: 50% 0; */
    background-size: cover;
    opacity: 0.2;
    z-index: -1;
}
</style>
